import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Stack
} from '@chakra-ui/react';
import BackButton from 'components/buttons/BackButton';

export default function DMSheets() {
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);

  return (
    <Flex direction="column" p="20px">
      {/* Top Section (Left-Aligned) */}
      <Box width="100%">
        <Flex alignItems="center" mb="20px">
          <BackButton
            isHovered={isBackButtonHovered}
            setIsHovered={setIsBackButtonHovered}
            aria-label="Go back"
          />
          <Text ml="10px" my="15px" fontWeight="bold" fontSize="25px">
            Internal DM Sheets Generator
          </Text>
        </Flex>

        <Box borderTop="2px solid green" mt="5px" width="100%" />
      </Box>

      {/* Centered Section for Table and Buttons */}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt="20px"
        width="100%"
      >
        <Box width="100%" overflowX="auto" mb="20px">
          <Table variant="simple" maxWidth="600px" mx="auto" mt="15px" textAlign="center">
            <caption style={{ captionSide: "top", fontWeight: "bold", marginBottom: "8px" }}>
              Basic DM Sheets Table
            </caption>
            <Thead>
              <Tr>
                <Th textAlign="center">Meow 1</Th>
                <Th textAlign="center">Meow 2</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td textAlign="center">Cell 1</Td>
                <Td textAlign="center">Cell 2</Td>
              </Tr>
              <Tr>
                <Td textAlign="center">Cell 3</Td>
                <Td textAlign="center">Cell 4</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Stack direction="row" spacing={4} justifyContent="center">
          <Button
            color="white"
            bg="gray.800"
            _hover={{ bg: "gray.700" }}
          >
            Button 1
          </Button>
          <Button
            color="white"
            bg="gray.800"
            _hover={{ bg: "gray.700" }}
          >
            Button 2
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
}