import React, { useState } from "react";
import { Box, Image, BoxProps, Text } from "@chakra-ui/react";
import { FaInstagram, FaTiktok, FaPlay } from "react-icons/fa"; 
import FullscreenModal from "../components/FullscreenModal";
import { isVideo } from "../utils/contentUtils";

interface FoodfluenceUGCImageProps extends BoxProps {
  link: string;
  idx: number;
  postUrl: string;
  collabDate: string;
  campaignName: string;
  platform: string;
  postType: string;
}

const FfUGCImage: React.FC<FoodfluenceUGCImageProps> = ({ link, idx, postUrl, collabDate, campaignName, platform, postType }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const contentIsVideo = isVideo(link);

  // Determine the tag text based on the postType
  const getPostTypeTag = () => {
    switch (postType) {
      case "instagramPost":
        return "Post";
      case "instagramReel":
        return "Reel";
      case "instagramStory":
        return "Story";
      case "tiktok":
        return "Post";
      default:
        return "";
    }
  };

  // Truncate campaign name if it exceeds 15 characters
  const truncateCampaignName = (name: string) => {
    return name.length > 25 ? `${name.substring(0, 23)}...` : name;
  };

  return (
    <Box
      w="100%"
      overflow="hidden"
      borderRadius="md"
      p={2}
      transition="transform 0.2s"
      position="relative"
    >
      {/* Campaign Name Tag */}
      <Box
        position="absolute"
        top="14px"
        left="14px"
        zIndex="1"
        backgroundColor="black"
        color="white"
        borderRadius="md"
        px={2}
        py={1}
        fontSize="sm"
        fontWeight="medium"
      >
        {truncateCampaignName(campaignName)}
      </Box>
      {/* Post Type Tag */}
      <Box
        position="absolute"
        top="40px"
        left="14px"
        zIndex="1"
        backgroundColor="black"
        color="white"
        borderRadius="md"
        px={2}
        py={1}
        fontSize="sm"
        fontWeight="light"
        mt="6px"
      >
        {getPostTypeTag()}
      </Box>
      <Box 
        backgroundColor={'#000'} 
        position={'relative'} 
        pt="100%" 
        borderRadius="md" 
        overflow={"hidden"}
        cursor="pointer"
        onClick={handleClick}
        _hover={{
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'blackAlpha.300',
            zIndex: 1,
          }
        }}
      >
        {contentIsVideo ? (
          <>
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
            >
              <video
                width="100%"
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center"
                }}
              >
                <source src={link} type="video/mp4" />
                An error has occurred loading the video.
              </video>
              {/* Video Play Icon Overlay */}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                backgroundColor="whiteAlpha.800"
                borderRadius="full"
                p={3}
                zIndex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FaPlay size="24px" />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Image
              src={link}
              alt={`Image ${idx + 1}`}
              objectFit="cover"
              borderRadius="md"
              position={'absolute'}
              top={'0px'}
              left={'0px'}
              width={"100%"}
              height={"100%"}
              onClick={(e) => e.stopPropagation()}
            />
          </>
        )}
      </Box>

      {/* Fullscreen Modal */}
      <FullscreenModal
        isOpen={isOpen}
        onClose={onClose}
        imageUrl={link}
        alt={`Image ${idx + 1}`}
        isVideo={contentIsVideo}
      />
    </Box>
  );
};

export default FfUGCImage; 