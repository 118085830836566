import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, Tooltip } from '@chakra-ui/react';
import { getCampaignViewsData } from 'services/firebaseService';

interface ViewData {
  month: string;
  instagram: number;
  tiktok: number;
}

interface TotalViewsProps {
  campaignId: string;
}

const INSTAGRAM_COLOR = '#38A169';  // Keep lighter green
const TIKTOK_COLOR = '#1A4731';     // Darker green for better contrast

const TotalViews: React.FC<TotalViewsProps> = ({ campaignId }) => {
  const [viewsData, setViewsData] = useState<ViewData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(50000); // Dynamic scale for y-axis

  useEffect(() => {
    const fetchViewsData = async () => {
      try {
        const data = await getCampaignViewsData(campaignId);
        setViewsData(data);
        
        // Calculate max value for y-axis scale
        const maxViews = Math.max(
          ...data.map(d => Math.max(d.instagram, d.tiktok))
        );
        // Round up to nearest 10000 and add 10000 for padding
        setMaxValue(Math.ceil(maxViews / 10000) * 10000 + 10000);
      } catch (error) {
        console.error("Error fetching views data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (campaignId) {
      fetchViewsData();
    }
  }, [campaignId]);

  const formatNumber = (num: number) => {
    if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
    if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
    return num.toString();
  };

  const getPathFromDataViews = (platform: 'instagram' | 'tiktok'): string => {
    if (!viewsData || viewsData.length === 0) return '';

    const points = viewsData.map((data, i) => {
      const x = 40 + i * (720 / (viewsData.length - 1));
      const y = 250 - (data[platform] / maxValue) * 200;
      return `${i === 0 ? 'M' : 'L'} ${x} ${y}`;
    });

    return points.join(' ');
  };

  const getPointCoordinates = (data: ViewData, index: number, platform: 'instagram' | 'tiktok') => {
    const x = 40 + index * (720 / (viewsData.length - 1));
    const y = 250 - (data[platform] / maxValue) * 200;
    return { x, y };
  };

  const getTooltipContent = (data: ViewData, index: number, platform: 'instagram' | 'tiktok') => {
    const currentViews = data[platform];
    const previousViews = index > 0 ? viewsData[index - 1][platform] : 0;
    const increase = currentViews - previousViews;
    const increaseText = increase > 0 ? `+${formatNumber(increase)}` : formatNumber(increase);
    
    return (
      <Box p={2}>
        <Text fontSize="lg" fontWeight="semibold" mb={1}>
          {formatNumber(currentViews)} views
        </Text>
        <Text fontSize="sm" color={increase >= 0 ? "green.500" : "red.500"}>
          {increaseText} from {viewsData[Math.max(0, index - 1)].month}
        </Text>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Box 
        border="2px solid"
        borderColor="gray.300"
        borderRadius="lg" 
        p={6}
        height="400px"
      >
        <Text>Loading views data...</Text>
      </Box>
    );
  }

  if (!viewsData || viewsData.length === 0) {
    return (
      <Box 
        border="2px solid"
        borderColor="gray.300"
        borderRadius="lg" 
        p={6}
        height="400px"
      >
        <Text>No data available</Text>
      </Box>
    );
  }

  return (
    <Box 
      border="2px solid"
      borderColor="gray.300"
      borderRadius="lg" 
      p={6}
      height="400px"
    >
      <Flex justify="space-between" align="center" mb={6}>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Total Views
          </Text>
          <Text fontSize="sm" color="gray.500">
            Monthly view trends across platforms
          </Text>
        </Box>
        <Flex gap={6}>
          <Flex align="center" gap={2}>
            <Box w="3" h="3" bg={INSTAGRAM_COLOR} borderRadius="full" />
            <Text fontSize="sm" color="gray.600">Instagram Reels</Text>
          </Flex>
          <Flex align="center" gap={2}>
            <Box w="3" h="3" bg={TIKTOK_COLOR} borderRadius="full" />
            <Text fontSize="sm" color="gray.600">TikTok</Text>
          </Flex>
        </Flex>
      </Flex>

      <Box height="250px" position="relative">
        <svg width="100%" height="100%" viewBox="-50 0 850 300" preserveAspectRatio="none">
          {/* Grid lines - Move x-axis line to back and adjust y position */}
          <line
            x1="40"
            y1="250"
            x2="760"
            y2="250"
            stroke="#CBD5E0"
            strokeWidth="1"
          />
          
          {/* Y-axis grid lines and labels */}
          {Array.from({ length: 6 }, (_, i) => {
            const value = (maxValue / 5) * i;
            return (
              <React.Fragment key={value}>
                {i > 0 && (
                  <line
                    x1="40"
                    y1={250 - (value / maxValue) * 200}
                    x2="760"
                    y2={250 - (value / maxValue) * 200}
                    stroke="#CBD5E0"
                    strokeWidth="1"
                  />
                )}
                <text
                  x="25"
                  y={250 - (value / maxValue) * 200}
                  fontSize="12"
                  fill="#718096"
                  dominantBaseline="middle"
                  textAnchor="end"
                >
                  {formatNumber(value)}
                </text>
              </React.Fragment>
            );
          })}

          {/* X-axis labels - Adjust y position */}
          {viewsData.map((data, i) => (
            <text
              key={data.month}
              x={40 + i * (720 / (viewsData.length - 1))}
              y="270"  // Moved down to avoid overlap
              fontSize="12"
              fill="#718096"
              textAnchor="middle"
            >
              {data.month}
            </text>
          ))}

          {/* Lines */}
          {['instagram', 'tiktok'].map((platform) => (
            <React.Fragment key={platform}>
              <path
                d={getPathFromDataViews(platform as 'instagram' | 'tiktok')}
                fill="none"
                stroke={platform === 'instagram' ? INSTAGRAM_COLOR : TIKTOK_COLOR}
                strokeWidth="3"
              />
              {/* Data points */}
              {viewsData.map((data, index) => {
                const { x, y } = getPointCoordinates(data, index, platform as 'instagram' | 'tiktok');
                return (
                  <Tooltip 
                    key={`${platform}-${index}`}
                    label={getTooltipContent(data, index, platform as 'instagram' | 'tiktok')}
                    placement="top"
                    hasArrow
                    bg="white"
                    color="gray.800"
                    p={0}
                    borderRadius="md"
                    boxShadow="lg"
                  >
                    <circle
                      cx={x}
                      cy={y}
                      r="4"
                      fill={platform === 'instagram' ? INSTAGRAM_COLOR : TIKTOK_COLOR}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                );
              })}
            </React.Fragment>
          ))}
        </svg>
      </Box>
    </Box>
  );
};

export default TotalViews;