import React, { createContext, useState, useEffect, useContext, useRef } from "react";
import { collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollaborationsData } from "../views/admin/foodfluence/types";
import { InfluencerCampaignsContext } from "./influencerCampaignsDataContext";

export const CollabsDataContext = createContext<Record<string, CollaborationsData>>({});

type ProviderProps = {
  children: React.ReactNode;
};

export const CollabsDataProvider = ({ children }: ProviderProps) => {
  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const [collabsData, setCollabsData] = useState<Record<string, CollaborationsData>>({});
  const batchedUpdates = useRef<Record<string, CollaborationsData>>({});
  const previousCampaignIds = useRef<string[]>([]);

  useEffect(() => {
    if (!influencerCampaigns || Object.keys(influencerCampaigns).length === 0) {
      setCollabsData({});
      batchedUpdates.current = {};
      previousCampaignIds.current = [];
      return;
    }

    const campaignIds = Object.keys(influencerCampaigns);

    // Check if campaign IDs have changed
    const prevIds = previousCampaignIds.current;
    const currentIds = campaignIds.sort().join(',');
    const previousIds = prevIds.sort().join(',');
    
    if (currentIds !== previousIds) {
      // Reset state when campaigns change
      setCollabsData({});
      batchedUpdates.current = {};
      previousCampaignIds.current = campaignIds;
    }

    const unsubscribes: (() => void)[] = [];
    let isInitialLoad = true;

    // Use a debounced function to batch updates
    const updateState = debounce(() => {
      setCollabsData(prev => ({
        ...prev,
        ...batchedUpdates.current
      }));
      batchedUpdates.current = {};
    }, 100);

    const setupCollabListeners = async () => {
      const collabPromises = campaignIds.map(campaignId => {
        return new Promise<void>((resolve) => {
          const collabsRef = collection(firestore, "collabs");
          const collabQuery = query(
            collabsRef,
            where("influencerCampaignID", "==", campaignId)
          );

          const unsubscribe = onSnapshot(collabQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "removed") {
                delete batchedUpdates.current[change.doc.id];
              } else if (change.doc.exists()) {
                batchedUpdates.current[change.doc.id] = {
                  id: change.doc.id,
                  ...change.doc.data()
                } as CollaborationsData;
              }
            });

            if (isInitialLoad) {
              resolve();
            } else {
              updateState();
            }
          });

          unsubscribes.push(unsubscribe);
        });
      });

      // Wait for initial data load
      await Promise.all(collabPromises);
      
      // Update state with initial data
      setCollabsData(prev => ({
        ...prev,
        ...batchedUpdates.current
      }));
      batchedUpdates.current = {};
      isInitialLoad = false;
    };

    setupCollabListeners();

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
      updateState.cancel();
    };
  }, [influencerCampaigns]);

  return (
    <CollabsDataContext.Provider value={collabsData}>
      {children}
    </CollabsDataContext.Provider>
  );
};

// Simple debounce utility
function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  
  const debouncedFn = (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };

  debouncedFn.cancel = () => {
    clearTimeout(timeout);
  };

  return debouncedFn;
}

export default CollabsDataProvider;
