import React, { useContext, useState } from 'react';
import { 
  Box, 
  Flex, 
  Text, 
  Button, 
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { FaUserPlus, FaCalendarAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import CollabTable from './components/collabTable';
import CreateCampaignModal from 'views/admin/foodfluence/components/CreateCampaignModal';
import CollabCalendar from 'views/admin/foodfluence/components/CollabCalendar';

interface ProfilesProps {
  clientID: string;
  campaignId: string;
}

const Profiles: React.FC<ProfilesProps> = ({ clientID, campaignId }) => {
  const history = useHistory();
  const collabsData = useContext(CollabsDataContext);
  const influencerCampaignsData = useContext(InfluencerCampaignsContext);
  const campaign = influencerCampaignsData[campaignId];
  
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [upcomingCollabsCount, setUpcomingCollabsCount] = useState(0);

  // Get collaborations for this campaign
  const collaborations = Object.values(collabsData).filter(
    collab => collab.influencerCampaignID === campaignId
  );

  const handleViewDetails = () => {
    setIsCreateCampaignModalOpen(true);
  };

  return (
    <Box 
      border="2px solid" 
      borderColor="gray.300"
      borderRadius="lg" 
      overflow="hidden"
      minHeight="calc(100vh - 200px)"
      display="flex"
      flexDirection="column"
    >
      <Flex justify="space-between" align="center" p={6} borderBottom="1px solid" borderColor="gray.200">
        <Text fontSize="lg" fontWeight="semibold">
          Campaign Collaborators ({collaborations.length})
        </Text>
        <Flex gap={2}>
          <Button
            bg="black"
            color="white"
            size="sm"
            borderRadius="lg"
            _hover={{ bg: "gray.800" }}
            onClick={handleViewDetails}
          >
            View Details
          </Button>
          <Box position="relative">
            <IconButton
              aria-label="View Calendar"
              icon={<FaCalendarAlt />}
              bg="black"
              color="white"
              borderRadius="lg"
              _hover={{ bg: "gray.800" }}
              onClick={() => setIsCalendarModalOpen(true)}
              size="sm"
            />
            {upcomingCollabsCount > 0 && (
              <Box
                position="absolute"
                top="-8px"
                right="-8px"
                bg="red.500"
                color="white"
                borderRadius="full"
                width="20px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="xs"
                fontWeight="bold"
              >
                {upcomingCollabsCount}
              </Box>
            )}
          </Box>
          <Button
            bg="black"
            color="white"
            size="sm"
            borderRadius="lg"
            leftIcon={<Icon as={FaUserPlus} />}
            onClick={() => history.push(`/admin/foodfluence/search?campaignID=${campaignId}&clientID=${clientID}`)}
            _hover={{ bg: 'gray.800' }}
          >
            Add Influencers
          </Button>
        </Flex>
      </Flex>

      <Box flex="1" overflow="auto">
        <CollabTable campaignId={campaignId} />
      </Box>

      <CreateCampaignModal
        isOpen={isCreateCampaignModalOpen}
        onClose={() => setIsCreateCampaignModalOpen(false)}
        clientID={clientID}
        campaignData={campaign}
      />
      <CollabCalendar
        campaignId={campaignId}
        isOpen={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        setUpcomingCollabsCount={setUpcomingCollabsCount}
        global={false}
      />
    </Box>
  );
};

export default Profiles;
