import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Flex,
  Box,
  Input,
  Textarea,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Link,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
} from '@chakra-ui/react';
import { useHistory, Route, Switch } from 'react-router-dom';
import { isSameDate } from '../utils/dateUtils';
import { FaPlus, FaMinus, FaEye, FaRedo } from 'react-icons/fa';
import { createNewInfluencerCampaign, uploadCollabImage, updateInfluencerCampaign, getClientFoodfluenceLocations, createFoodfluenceLocation, getClientData } from 'services/firebaseService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdUpload} from "react-icons/md";
import { InfluencerCampaign, DeliverableCounts, OpenHours, FoodfluenceLocation } from '../types';
import AddressInput from './addressSelector';
import { LocationList } from './LocationList';

interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientID: string;
  campaignData?: InfluencerCampaign; // Optional parameter for existing campaign data
}

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({ isOpen, onClose, clientID, campaignData }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tikTokCount, setTikTokCount] = useState(0);
  const [instagramStoryCount, setInstagramStoryCount] = useState(0);
  const [instagramReelCount, setInstagramReelCount] = useState(0);
  const [instagramPostCount, setInstagramPostCount] = useState(0);
  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');
  const [cash, setCash] = useState('0');
  const [credit, setCredit] = useState('0');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [collabImage, setCollabImage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [locations, setLocations] = useState<FoodfluenceLocation[]>([]);
  const [existingLocationAddresses, setExistingLocationAddresses] = useState<Set<string>>(new Set());
  const [clientLocationAddresses, setClientLocationAddresses] = useState<Set<string>>(new Set());
  const [clientBusinessType, setClientBusinessType] = useState<string | null>(null);
  const [selectedCampaignType, setSelectedCampaignType] = useState<string>(null);
  const [allowCounters, setAllowCounters] = useState(true);
  const absoluteMaxPartySize = 5;
  const [selectedHours, setSelectedHours] = useState<OpenHours>({
    Monday: new Set(),
    Tuesday: new Set(),
    Wednesday: new Set(),
    Thursday: new Set(),
    Friday: new Set(),
    Saturday: new Set(),
    Sunday: new Set(),
  });
  const [isDragging, setIsDragging] = useState(false);

  const history = useHistory();

  const [isUpdateAlertOpen, setIsUpdateAlertOpen] = useState(false);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);
  const cancelRef = useRef();

  const [clientData, setClientData] = useState<any>(null); // Adjust type as necessary

  const [maxPartySize, setMaxPartySize] = useState<number | null>(null);

  useEffect(() => {
    const fetchClientData = async () => {
      const data = await getClientData(clientID);
      setClientData(data);
      if (data["businessType"]) {
        setClientBusinessType(data["businessType"]);
      }
      else{
        setClientBusinessType('inStore');  
      }
    };

    if (isOpen) {
      fetchClientData();
    }
  }, [isOpen, clientID]);

  useEffect(() => {
    if (campaignData) {
      setCampaignName(campaignData.name);
      setCampaignDescription(campaignData.description);
      setTikTokCount(campaignData.deliverableCounts.tiktoks as number);
      setInstagramStoryCount(campaignData.deliverableCounts.instagramStories as number);
      setInstagramReelCount(campaignData.deliverableCounts.instagramReels as number);
      setInstagramPostCount(campaignData.deliverableCounts.instagramPosts as number);
      setCash(campaignData.cash?.toString() || '0');
      setCredit(campaignData.credit?.toString() || '0');
      setStartDate(campaignData.startDate?.toDate());
      setEndDate(campaignData.endDate?.toDate());
      setCollabImage(campaignData.collabImage);
      setAllowCounters(campaignData.allowCounters || true);
      setSelectedHours(
        {
          Monday: new Set(campaignData.openHours?.Monday || []),
          Tuesday: new Set(campaignData.openHours?.Tuesday || []),
          Wednesday: new Set(campaignData.openHours?.Wednesday || []),
          Thursday: new Set(campaignData.openHours?.Thursday || []),
          Friday: new Set(campaignData.openHours?.Friday || []),
          Saturday: new Set(campaignData.openHours?.Saturday || []),
          Sunday: new Set(campaignData.openHours?.Sunday || []),
        }
      );
      if (campaignData.campaignType) {
        setSelectedCampaignType(campaignData.campaignType);
      }
      else if (clientBusinessType === 'online') {
        setSelectedCampaignType('shipToHome');
      }
      else {
        setSelectedCampaignType('inStore');
      }
      setMaxPartySize(campaignData?.maxPartySize || null);
      setIsEditing(true);
    } else {
      // Reset state for new campaign creation
      setIsEditing(false);
      setCampaignName('');
      setCampaignDescription('');
      setTikTokCount(0);
      setInstagramStoryCount(0);
      setInstagramReelCount(0);
      setInstagramPostCount(0);
      setCash('0');
      setCredit('0');
      setEndDate(null)
      setStartDate(new Date());
      setAllowCounters(true);
      setSelectedHours({
        Monday: new Set(),
        Tuesday: new Set(),
        Wednesday: new Set(),
        Thursday: new Set(),
        Friday: new Set(),
        Saturday: new Set(),
        Sunday: new Set(),
      });
      setMaxPartySize(null);

      if (clientBusinessType === 'online') {
        setSelectedCampaignType('shipToHome');
      }
      else if (clientBusinessType === 'inStore') {
        setSelectedCampaignType('inStore');
      }
      else {
        setSelectedCampaignType(null);
      }

      setCollabImage(null);
    }
  }, [campaignData, isOpen, clientBusinessType]);

  useEffect(() => {
    const fetchClientLocations = async () => {
      try {
        const clientLocations = await getClientFoodfluenceLocations(clientID);
        setClientLocationAddresses(new Set(clientLocations.map(loc => loc.fullAddress)));
        if (!campaignData) {
          setLocations(clientLocations);
          setExistingLocationAddresses(new Set(clientLocations.map(loc => loc.fullAddress)));
        } else {
          const currentLocationsAddresses = campaignData.foodfluenceLocations || [];
          const newLocations = clientLocations.filter(loc => currentLocationsAddresses.includes(loc.docId));
          setLocations(newLocations);
          setExistingLocationAddresses(new Set(newLocations.map(loc => loc.fullAddress)));
        }
      } catch (error) {
        console.error('Error fetching client locations:', error);
      }
    };

    if (isOpen && (selectedCampaignType === 'inStore' || !clientData?.businessType || clientData?.businessType === 'inStore')) {
      fetchClientLocations();
    }
  }, [isOpen, clientID, campaignData, selectedCampaignType, clientData?.businessType]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (value: any) => {
    if (typeof value === 'number' && value < 0) {
      setter(0);
    } else {
      setter(value);
    }
  };

  const isFormValid = () => {
    const baseValidation = (
      campaignName && 
      campaignDescription && 
      (Number(cash) > 0 || Number(credit) > 0 || selectedCampaignType !== 'inStore') && 
      startDate && 
      endDate && 
      (tikTokCount > 0 || instagramStoryCount > 0 || instagramReelCount > 0 || instagramPostCount > 0) && 
      (selectedCampaignType !== null)
    );

    if (selectedCampaignType === 'inStore') {
      return baseValidation && !isOpenHoursEmpty() && locations.length > 0 && maxPartySize !== null;
    }

    return baseValidation;
  };

  const isFormEmpty = () => {
    return (
      campaignName === '' && 
      campaignDescription === '' && 
      cash === '0' && 
      credit === '0' && 
      isSameDate(startDate, new Date()) && 
      endDate === null && 
      tikTokCount === 0 && 
      instagramStoryCount === 0 && 
      instagramReelCount === 0 && 
      instagramPostCount === 0 && 
      isOpenHoursEmpty()
    );
  };

  const isOpenHoursEmpty = () => {
    return !selectedHours || Object.values(selectedHours).every(hours => hours.size === 0);
  };

  const handleFormSubmission = async () => {
    try {
      let locationAddresses = [];

      if (selectedCampaignType === 'inStore') {
        const newLocations = locations.filter(loc => !clientLocationAddresses.has(loc.fullAddress));
        
        for (const location of newLocations) {
          await createFoodfluenceLocation(location, clientID, []);
        }
        locationAddresses = locations.map(loc => loc.fullAddress);
      }

      const deliverableCounts: DeliverableCounts = {
        'tiktoks': tikTokCount,
        'instagramReels': instagramReelCount,
        'instagramStories': instagramStoryCount,
        'instagramPosts': instagramPostCount,
      };

      const sortedOpenHours = Object.fromEntries(
        Object.entries(selectedHours).map(([day, hours]) => [
          day,
          Array.from(hours as Set<string>).sort((a, b) => {
            const timeA = new Date(`1970-01-01T${a}`);
            const timeB = new Date(`1970-01-01T${b}`);
            return timeA.getTime() - timeB.getTime();
          })
        ])
      );

      if (isEditing) {
        await updateInfluencerCampaign(
          campaignData.id,
          clientID,
          campaignName,
          campaignDescription,
          deliverableCounts,
          Number(cash),
          selectedCampaignType === 'inStore' ? Number(credit) : 0,
          startDate,
          endDate,
          selectedCampaignType === 'inStore' ? sortedOpenHours : null,
          collabImage,
          selectedCampaignType === 'inStore' ? locationAddresses : [],
          selectedCampaignType as 'inStore' | 'shipToHome',
          allowCounters,
          maxPartySize 
        );
        onClose();
      } else {
        const campaignID = await createNewInfluencerCampaign(
          clientID,
          campaignName,
          campaignDescription,
          deliverableCounts,
          Number(cash),
          selectedCampaignType === 'inStore' ? Number(credit) : 0,
          startDate,
          endDate,
          selectedCampaignType === 'inStore' ? sortedOpenHours : null,
          collabImage,
          selectedCampaignType === 'inStore' ? locationAddresses : [],
          selectedCampaignType as 'inStore' | 'shipToHome',
          allowCounters,
          maxPartySize
        );
        onClose();
        history.push(`/admin/foodfluence/search?campaignID=${campaignID}&clientID=${clientID}`);
      }
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const collabImageURL = await uploadCollabImage(clientID, campaignName, uint8Array);
      setCollabImage(collabImageURL);
    }
  };

  const hasFormChanged = () => {
    if (!campaignData) return false;

    const deliverableCountsChanged = 
      tikTokCount !== campaignData.deliverableCounts.tiktoks ||
      instagramStoryCount !== campaignData.deliverableCounts.instagramStories ||
      instagramReelCount !== campaignData.deliverableCounts.instagramReels ||
      instagramPostCount !== campaignData.deliverableCounts.instagramPosts;

      const datesChanged = 
      (startDate != null && !isSameDate(startDate, campaignData.startDate?.toDate()) ||
      (endDate != null && !isSameDate(endDate, campaignData.endDate?.toDate())));

    const openHoursChanged = selectedCampaignType === 'inStore' && (campaignData?.openHours
      ? Object.keys(selectedHours).some(day => {
          const currentHours = Array.from(selectedHours[day]).sort();
          const campaignHours = Array.from(campaignData.openHours[day] || []).sort();
          return JSON.stringify(currentHours) !== JSON.stringify(campaignHours);
        })
      : Object.values(selectedHours).some(hours => hours.size > 0));

    const nameChanged = campaignName !== campaignData.name;
    const descriptionChanged = campaignDescription !== campaignData.description;
    const cashChanged = cash !== campaignData.cash?.toString();
    const creditChanged = credit !== campaignData.credit?.toString();
    const collabImageChanged = collabImage !== campaignData.collabImage;
    const locationsChanged = selectedCampaignType === 'inStore' && 
      locations.map(loc => loc.fullAddress).sort().join(',') !== Array.from(existingLocationAddresses).sort().join(',');
    const maxPartySizeChanged = maxPartySize !== campaignData?.maxPartySize;
    return ( 
      nameChanged ||
      descriptionChanged ||
      deliverableCountsChanged ||
      cashChanged ||
      creditChanged ||
      datesChanged ||
      collabImageChanged ||
      openHoursChanged ||
      locationsChanged ||
      maxPartySizeChanged
    );
  };

  const generateTimeOptions = () => {
    const times = [];
    let startTime = new Date();
    startTime.setHours(8, 0, 0, 0); // Start at 8:00 AM
    for (let i = 0; i < 14; i++) {
      const timeString = startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      times.push(timeString);
      startTime.setHours(startTime.getHours() + 1);
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  const handleHourClick = (day: string, hour: string) => {

    setSelectedHours(prev => {
      const newSet = new Set(prev[day]);
      if (newSet.has(hour)) {
        newSet.delete(hour);
      } else {
        newSet.add(hour);
      }
      return { ...prev, [day]: newSet };
    });
  };

  const handleMouseDown = (day: string, hour: string) => {
    setIsDragging(true);
    handleHourClick(day, hour);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = (day: string, hour: string) => {
    if (!isDragging) return;
    handleHourClick(day, hour);
  };

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mouseup', handleGlobalMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, []);

  const handleUpdateConfirm = () => {
    setIsUpdateAlertOpen(false);
    handleFormSubmission();
  };

  const handleCancelConfirm = () => {
    setIsCancelAlertOpen(false);
    onClose();
  };

  const handleRemoveLocation = (locationAddress: string) => {
    setLocations(locations.filter(loc => loc.fullAddress !== locationAddress));
  };

  const handleCloseAttempt = () => {
    if (isEditing && hasFormChanged() || !isEditing && !isFormEmpty()) {
      setIsCancelAlertOpen(true);
    } else {
      onClose();
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseAttempt} size="xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>{isEditing ? 'Campaign Details' : 'Create New Campaign'}</ModalHeader>
          <ModalBody>
            <Box>
              {/* Campaign Name */}
              <Box mb={4}>
                <FormControl>
                  <FormLabel fontWeight="bold">Campaign Name:</FormLabel>
                  <Input
                    placeholder="Enter campaign name"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                  />
                </FormControl>
              </Box>

              {/* Campaign Type - only show when creating new campaign */}
              {clientBusinessType === 'hybrid' && (
                <Box mb={4}>
                  <FormControl>
                    <FormLabel fontWeight="bold">Campaign Type:</FormLabel>
                    <RadioGroup 
                      onChange={setSelectedCampaignType} 
                      value={selectedCampaignType}
                      isDisabled={isEditing}
                    >
                      <Stack direction="row" spacing={4}>
                        <Radio value="shipToHome">Ship to home</Radio>
                        <Radio value="inStore">In Store</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}

              {/* Campaign Description */}
              <Box mb={4}>
                <FormControl>
                  <FormLabel fontWeight="bold">Campaign Description:</FormLabel>
                  <Textarea
                    placeholder="Enter campaign description"
                    value={campaignDescription}
                    onChange={(e) => setCampaignDescription(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Text fontWeight="bold" mb={2}>Content Types:</Text>
              <Stack spacing={4}>
                {[
                  { label: 'TikTok', count: tikTokCount, setCount: setTikTokCount },
                  { label: 'Instagram Story', count: instagramStoryCount, setCount: setInstagramStoryCount },
                  { label: 'Instagram Reel', count: instagramReelCount, setCount: setInstagramReelCount },
                  { label: 'Instagram Post', count: instagramPostCount, setCount: setInstagramPostCount }
                ].map(({ label, count, setCount }) => (
                  <Flex key={label} alignItems="center" justifyContent="space-between">
                    <Text>{label}</Text>
                    <Flex alignItems="center">
                      <IconButton
                        icon={<FaMinus style={{ color: 'red' }} />}
                        aria-label={`Decrease ${label} count`}
                        onClick={() => handleInputChange(setCount)(count - 1)}
                        isDisabled={count <= 0}
                      />
                      <Text mx={2}>{count}</Text>
                      <IconButton
                        icon={<FaPlus style={{ color: 'green' }} />}
                        aria-label={`Increase ${label} count`}
                        onClick={() => handleInputChange(setCount)(count + 1)}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Stack>
              <Box mt={4}>
                <FormControl>
                  <FormLabel fontWeight="bold">Cash Amount ($):</FormLabel>
                  <Input
                    type="number"
                    placeholder="Cash amount"
                    value={cash}
                    onChange={(e) => {
                      const newValue = Math.max(0, Number(e.target.value));
                      setCash(newValue.toString());
                    }}
                    min="0"
                  />
                </FormControl>
              </Box>
              {(selectedCampaignType === 'inStore' || !clientData?.businessType || clientData?.businessType === 'inStore') && (
                <Box mt={4}>
                  <FormControl>
                    <FormLabel fontWeight="bold">Store Credit Amount:</FormLabel>
                    <Input
                      type="number"
                      placeholder="Credit amount"
                      value={credit}
                      onChange={(e) => {
                        const newValue = Math.max(0, Number(e.target.value));
                        setCredit(newValue.toString());
                      }}
                      min="0"
                    />
                  </FormControl>
                </Box>
              )}
              <Box mt={4}>
                <Checkbox
                  isChecked={allowCounters}
                  onChange={(e) => setAllowCounters(e.target.checked)}
                >
                  Allow Influencers To Counter
                </Checkbox>
              </Box>
              {(selectedCampaignType === 'inStore' || !clientData?.businessType || clientData?.businessType === 'inStore') && (
                <>
                  <Box mt={4}>
                    <AddressInput
                      locations={locations}
                      setLocations={setLocations}
                      isRequired={true}
                    />
                    <LocationList locations={locations} onRemoveLocation={handleRemoveLocation} />
                  </Box>
                  <Box mt={8}>
                    <Text fontWeight="bold" mb={2}>Select Weekly Hours for Collaboration (Hold and drag to select multiple hours):</Text>
                    <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gridGap={1}>
                      <Box></Box>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                        <Box key={day} textAlign="center">{day}</Box>
                      ))}
                      {timeOptions.map(time => (
                        <React.Fragment key={time}>
                          <Box textAlign="center" userSelect="none">{time}</Box>
                          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                            <Box
                              key={`${day}-${time}`}
                              bg={selectedHours[day].has(time) ? 'green.200' : 'gray.200'}
                              onMouseDown={() => handleMouseDown(day, time)}
                              onMouseUp={handleMouseUp}
                              onMouseEnter={() => handleMouseEnter(day, time)}
                              cursor="pointer"
                              height="20px"
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  </Box>

                  {/* New Box for Party Size Dropdown */}
                  <Box mt={4}>
                    <Text mb={2}>Max Party Size:</Text>
                    <Box display="flex" gap={2}>
                      {[...Array(absoluteMaxPartySize).keys()].map(num => (
                        <Box
                          key={num + 1}
                          width="40px"
                          height="40px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="md"
                          border="1px solid"
                          borderColor={maxPartySize === num + 1 ? "blue.500" : "gray.300"}
                          bg={maxPartySize === num + 1 ? "blue.100" : "white"}
                          cursor="pointer"
                          onClick={() => setMaxPartySize(num + 1)}
                        >
                          {num + 1}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
              <Flex direction="row" justifyContent="space-between">
                <Box mt={4}>
                  <Text fontWeight="bold" mb={2}>Start Date:</Text>
                  <DatePicker
                    id="startDatePicker"
                    selected={startDate}
                    onChange={(date: Date) => {
                     
                        setStartDate(date);
                        if (endDate && date > endDate) {
                          setEndDate(null);
                        }
                      
                    }}
                    dateFormat="MMMM d, yyyy"
                    minDate={new Date()}
                    placeholderText="Select start date"
                    disabled={campaignData?.startDate && new Date() >= campaignData?.startDate?.toDate()}
                  />
                </Box>
                <Box mt={4}>
                  <Text fontWeight="bold" mb={2}>End Date:</Text>
                  <DatePicker
                    id="endDatePicker"
                    selected={endDate}
                    onChange={(date: Date) => {
                      if ((!campaignData || date >= campaignData.endDate?.toDate())) {
                        setEndDate(date);
                      }
                    }}
                    dateFormat="MMMM d, yyyy"
                    minDate={startDate || new Date()}
                    placeholderText="Select end date"
                  />
                </Box>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" mt={4}>
                <Text fontWeight="bold" mb={2}>Upload Image (Optional):</Text>
                {!collabImage ? (
                  <Button
                    rightIcon={<MdUpload />}
                    aria-label="Upload Image"
                    onClick={() => inputRef.current?.click()}
                    bg="black"
                    color="white"
                    position="relative"
                    mt={2}
                    _hover={{ bg: "blackAlpha.600" }}
                  >
                    Upload
                  </Button>
                ) : (
                  <Flex>
                    <Link href={collabImage} isExternal>
                      <IconButton icon={<FaEye size={30} />} aria-label="View Image" color="green" />
                    </Link>
                      <Button
                        ml={2}
                        bg="red.500"
                        color="white"
                        _hover={{ bg: "red.600" }}
                        onClick={() => {
                          setCollabImage(null);
                        }}
                        borderRadius="full"
                      >
                        <FaRedo size={12} />
                      </Button>
                  </Flex>
                )}
              </Flex>
              <Input
                type="file"
                ref={inputRef}
                display="none"
                onChange={handleFileChange}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            {isEditing ? (
              <Button colorScheme="green" mr={3} onClick={() => setIsUpdateAlertOpen(true)} isDisabled={!hasFormChanged() || !isFormValid()}>
                Update
              </Button>
            ) : (
              <Button colorScheme="green" mr={3} onClick={() => handleFormSubmission()} isDisabled={!isFormValid()}>
                Create
              </Button>
            )}
            <Button variant="ghost" onClick={handleCloseAttempt}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isUpdateAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsUpdateAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Update Campaign
            </AlertDialogHeader>
            <AlertDialogBody>
              Changes to content requirements and compensation will not affect previously sent collaborations.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsUpdateAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleUpdateConfirm} ml={3}>
                Update
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsCancelAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel Changes
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? All changes will be discarded.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsCancelAlertOpen(false)}>
                No
              </Button>
              <Button colorScheme="red" onClick={handleCancelConfirm} ml={3}>
                Yes, Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CreateCampaignModal;
