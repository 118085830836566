import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Spinner, Text, Center, Heading, Flex, keyframes } from '@chakra-ui/react';
import { FaPlayCircle, FaCircle, FaImage, FaTiktok } from 'react-icons/fa';
import PerformanceSummary from './components/performanceSummary';
import ContentAnalysis from './components/contentAnalysis';
import TotalViews from './components/totalViews';

const pulse = keyframes`
  0% { transform: scale(0.95); opacity: 0.5; }
  50% { transform: scale(1); opacity: 0.8; }
  100% { transform: scale(0.95); opacity: 0.5; }
`;

interface OverviewProps {
  clientID: string;
  campaignId: string;
  metricsData: any;
  isLoading: boolean;
  error: string | null;
}

const Overview: React.FC<OverviewProps> = ({ 
  clientID, 
  campaignId, 
  metricsData, 
  isLoading, 
  error 
}) => {
  if (isLoading) {
    return (
      <Center height="calc(100vh - 200px)">
        <Flex
          direction="column"
          align="center"
          justify="center"
          bg="white"
          p={8}
          borderRadius="2xl"
          boxShadow="lg"
          animation={`${pulse} 2s infinite`}
        >
          <Box position="relative" mb={6}>
            <Spinner
              thickness="3px"
              speed="0.8s"
              emptyColor="gray.200"
              color="black"
              size="xl"
              label="Loading campaign metrics"
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width="70%"
              height="70%"
              borderRadius="full"
              border="3px solid"
              borderColor="gray.100"
            />
          </Box>
          <Text 
            fontSize="md" 
            fontWeight="medium"
            color="gray.600"
            textAlign="center"
          >
            Loading campaign metrics...
          </Text>
        </Flex>
      </Center>
    );
  }

  return (
    <Box 
      height="calc(100vh - 200px)" 
      overflow="hidden"
      position="relative"
    >
      <Box p={4} height="100%">
        <Flex direction="column" height="100%" gap={6}>
          <PerformanceSummary campaignId={campaignId} />
          <SimpleGrid 
            columns={2} 
            spacing={8} 
            flex={1}
            minHeight={0}
          >
            <ContentAnalysis campaignId={campaignId} />
            <TotalViews campaignId={campaignId} />
          </SimpleGrid>
        </Flex>
      </Box>
    </Box>
  );
};

export default Overview;