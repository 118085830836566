import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Flex, Icon, SimpleGrid } from '@chakra-ui/react';
import { FaArrowUp, FaArrowDown, FaListUl, FaEye, FaChartLine, FaUsers, FaSpinner, FaCheckCircle, FaMinus } from 'react-icons/fa';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { formatCount } from '../utils/metricsUtils';
import { AdminDataContext } from 'contexts/adminDataContext';

const inProgressStatuses = [
  "pendingRedemption",
  "collabRedeemed",
  "pendingSubmission"
];

const Analytics = () => {
  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const influencers = useContext(InfluencersDataContext);

  const collaborations = useContext(CollabsDataContext);

  const { adminData } = useContext(AdminDataContext);

  const totalCreators = Object.values(collaborations || {}).length;
  
  const totalInProgress = Object.values(collaborations || {}).filter(
    collab => inProgressStatuses.includes(collab.status)
  ).length;

  const completedCollaborations = Object.values(collaborations || {}).filter(
    collab => collab.status === 'collabCompleted'
  ).length;

  const getTotalReachForCampaigns = (): number => {
    const uniqueInfluencers = new Map<string, number>();
    
    Object.values(collaborations || {}).forEach(collab => {
      if (collab.status === "collabCompleted") {
        const influencer = influencers[collab.influencerID];
        if (influencer) {
          uniqueInfluencers.set(collab.influencerID, influencer.follower_count);
        }
      }
    });

    const reach = Array.from(uniqueInfluencers.values())
      .reduce((total, followerCount) => {
        return total + Number(followerCount);
      }, 0);

    return reach;
  };

  const [totalReach, setTotalReach] = useState<number>(0);

  useEffect(() => {
    const reach = getTotalReachForCampaigns();
    setTotalReach(reach);
  }, [collaborations, influencers]);

  const calculateCompletionRate = () => {
    return totalCreators > 0 ? Math.round((completedCollaborations / totalCreators) * 100) : 0;

   }
  

  const completionRate = calculateCompletionRate().toString() + '%';

  const getFirstDayOfPreviousMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getInvitesSinceBilling = () => {
    if (!collaborations) return 0;
    
    const startDate = adminData?.billingDate 
      ? (adminData.billingDate.toDate 
        ? adminData.billingDate.toDate() 
        : new Date(adminData.billingDate))
      : getFirstDayOfPreviousMonth();
    
    return Object.values(collaborations).filter(collab => {
      if (!collab.collabReceivedTime) return false;
      
      const collabDate = collab.collabReceivedTime.toDate();
      return collabDate > startDate;
    }).length;
  };

  const getInProgressSinceBilling = () => {
    if (!collaborations) return 0;
    
    const startDate = adminData?.billingDate 
      ? (adminData.billingDate.toDate 
        ? adminData.billingDate.toDate() 
        : new Date(adminData.billingDate))
      : getFirstDayOfPreviousMonth();
    
    return Object.values(collaborations).filter(collab => {
      if (!collab.collabAcceptedTime) return false;
      
      const collabDate = collab.collabAcceptedTime.toDate();
      return collabDate > startDate && inProgressStatuses.includes(collab.status);
    }).length;
  };

  const getCompletedSinceBilling = () => {
    if (!collaborations) return 0;
    
    const startDate = adminData?.billingDate 
      ? (adminData.billingDate.toDate 
        ? adminData.billingDate.toDate() 
        : new Date(adminData.billingDate))
      : getFirstDayOfPreviousMonth();
    
    return Object.values(collaborations).filter(collab => {
      if (!collab.logs || collab.logs.length === 0) return false;
      
      const lastLog = collab.logs[collab.logs.length - 1];
      if (!lastLog.timestamp) return false;
      
      const completionDate = lastLog.timestamp.toDate();
      return completionDate > startDate && collab.status === 'collabCompleted';
    }).length;
  };

  const boxContents = [
    { 
      label: 'Invites Sent', 
      value: totalCreators.toString(), 
      trend: getInvitesSinceBilling().toString(),
      icon: FaUsers,
      iconColor: "blue.500"
    },
    { 
      label: 'In Progress', 
      value: totalInProgress.toString(), 
      trend: getInProgressSinceBilling().toString(),
      icon: FaSpinner,
      iconColor: "orange.500"
    },
    { 
      label: 'Completed', 
      value: completedCollaborations.toString(), 
      trend: getCompletedSinceBilling().toString(),
      icon: FaCheckCircle,
      iconColor: "green.500"
    },
    { 
      label: 'Maximum Reach', 
      value: formatCount(totalReach), 
      icon: FaEye,
      iconColor: "purple.500"
    },
  ];

  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4} width="100%">
      {boxContents.map(({ label, value, trend, icon, iconColor }, index) => (
        <Box
          key={index}
          bg="white"
          borderRadius="xl"
          p={5}
          position="relative"
          border="1px solid"
          borderColor="gray.300"
          height="auto"
        >
          <Flex justify="space-between" align="start" mb={1.5}>
            <Text
              fontSize="14px"
              color="gray.700"
              fontWeight="500"
              letterSpacing="-0.2px"
            >
              {label}
            </Text>
            <Box
              bg={`${iconColor}20`}
              p={1.5}
              borderRadius="md"
              width="38px"
              height="38px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="1px solid"
              borderColor="gray.200"
              boxShadow="0px 1px 2px rgba(0, 0, 0, 0.06)"
            >
              <Icon
                as={icon}
                color={iconColor}
                w={5}
                h={5}
              />
            </Box>
          </Flex>
          
          <Flex align="center" gap={2}>
            <Text
              fontSize="28px"
              fontWeight="600"
              color="gray.900"
              letterSpacing="-1px"
              lineHeight="1"
              mb={0.5}
            >
              {value}
            </Text>
            
            {trend && (
              <Flex
                align="center"
                bg={Number(trend) === 0 
                  ? "gray.300" 
                  : "green.100"
                }
                color={Number(trend) === 0 
                  ? "gray.700" 
                  : "green.700"
                }
                px={Number(trend) === 0 ? 3 : 2}
                py={0.5}
                borderRadius="full"
                fontSize="12px"
                fontWeight="500"
                height="fit-content"
                gap={1}
              >
                {Number(trend) === 0 ? (
                  <Icon as={FaMinus} w={4} h={3} />
                ) : (
                  <>
                    <Icon as={FaArrowUp} w={3} h={3} />
                    {trend}
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default Analytics;