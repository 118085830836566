import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Select,
  Spinner,
  Icon,
  Stack,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  VStack
} from "@chakra-ui/react";
import { FaStar, FaChevronDown } from "react-icons/fa6";
import { AccountsDataContext } from "contexts/accountsDataContext";
import AccountsDropdown from "../../components/accountsDropDown";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import InfluencersList from './components/InfluencersList'
import Pagination from './components/Pagination';
import { useLocation, useHistory } from "react-router-dom";
import { setCampaignInfluencers, createCollaboration, getInfluencerCampaign, getAllInfluencers, updateAdminUserRemainingInvites } from "services/firebaseService";
import { getDocFromCache } from "firebase/firestore";
import Environment from "environment";
import { Influencer, InfluencerCampaign } from "../../types";
import { AdminDataContext } from "contexts/adminDataContext";
import { PaymentModal } from './components/PaymentModal';
import { sendCollabInviteSms, sendCollabInviteEmail } from "services/flaskService";
import { UpgradeModal } from './components/upgradeModal';
import { CollabsDataContext } from "contexts/collabsDataContext";
import LaunchConfirmationModal from './components/LaunchConfirmationModal';

export default function Search() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const clientID = queryParams.get("clientID");
  const campaignID = queryParams.get("campaignID");

  const client = useContext(AccountsDataContext)[clientID];

  const {adminData, isLoading} = useContext(AdminDataContext)
  
  const [influencers, setInfluencers] = useState<Record<string, Influencer>>({});
  
  const collaborations = useContext(CollabsDataContext);

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const fetchedInfluencers = await getAllInfluencers();
        setInfluencers(fetchedInfluencers);
      } catch (error) {
        console.error("Error fetching influencers:", error);
      }
    };

    fetchInfluencers();
  }, []);

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const [filterFeatured, setFilterFeatured] = useState(false);
  const [followerRange, setFollowerRange] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [visibleInfluencers, setVisibleInfluencers] = useState<Influencer[][]>([[]]);
  const [currPage, setCurrPage] = useState(0);

  const [selectedInfluencers, setSelectedInfluencers] = useState<Set<string>>(new Set());
  const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isLaunchConfirmationOpen, setIsLaunchConfirmationOpen] = useState(false);
  const [hasCashPayment, setHasCashPayment] = useState(false);
  
  const toggleInfluencerSelected = (influencerID: string) => {
    setSelectedInfluencers(prevSelected => {
      const updatedSet = new Set(prevSelected);
      
      // If influencer is already selected, allow deselecting
      if (updatedSet.has(influencerID)) {
        updatedSet.delete(influencerID);
        return updatedSet;
      }
      
      // Show upgrade modal if no invites remaining
      if (updatedSet.size >= adminData.invitesRemaining) {
        setIsUpgradeModalOpen(true);
        return prevSelected; // Return unchanged
      }
      
      // Otherwise, add the new influencer
      updatedSet.add(influencerID);
      return updatedSet;
    });
  }

  useEffect(() => {
    const filteredInfluencers = getFilteredInfluencers();
    const influencersByPage = groupInfluencersByPage(filteredInfluencers ?? [], 50); // Increased from 20 to 50
    setVisibleInfluencers(influencersByPage);
    setCurrPage(0);
  }, [influencers, searchTerm, selectedState, selectedCity, filterBookmarked, filterSelected, filterFeatured, followerRange, client?.id, selectedTags, campaignID]);

  const getActiveCreators = (campaignId: string) => {
    const activeInfluencers = new Set(
      Object.values(collaborations)
        .filter(collab => collab.influencerCampaignID === campaignId)
        .map(collab => collab.influencerID)
    );
    
    return activeInfluencers;
  };

  const getFilteredInfluencers = () => {
    const activeCreators = new Set<string>();
    
    if (campaignID) {
      getActiveCreators(campaignID).forEach(creatorId => activeCreators.add(creatorId));
    }

    const filtered = Object.keys(influencers ?? {})
      .map(key => {
        const influencer = influencers[key];
        // Calculate the higher engagement rate
        const tiktokRate = influencer.tiktokEngagementRate || 0;
        const instaRate = influencer.engagement_rate || 0;
        influencer.displayEngagement = Math.max(tiktokRate, instaRate) || null;
        influencer.name = (influencer.firstName || '') + 
          (influencer.lastName && influencer.lastName !== "unknown" ? " " + influencer.lastName : "");
        return influencer;
      })
      .filter(influencer => {
        // Only filter out influencers who are part of the current campaign
        if (campaignID && activeCreators.has(influencer.id)) {
          return false;
        }
        if (campaignID && (!influencer.email_address || influencer.email_address.trim() === '')) {
          return false;
        }

        const searchTermLower = searchTerm.toLowerCase();
        const matchesSearchTerm =
          (influencer.name || '').toLowerCase().includes(searchTermLower) ||
          (influencer.email_address || '').toLowerCase().includes(searchTermLower) ||
          (influencer.instagramHandle || '').toLowerCase().includes(searchTermLower);

        const matchesState = selectedState ? influencer.state === selectedState : true;
        const matchesCity = selectedCity ? influencer.city === selectedCity : true;
        const matchedBookmark = filterBookmarked ? client?.bookmarkedInfluencers?.includes(influencer.id) : true;
        const matchedSelected = filterSelected ? selectedInfluencers.has(influencer.id) : true;
        const matchedFeatured = filterFeatured ? influencer.featured === filterFeatured : true;
        const matchedFollowerRange = followerRange ? (followerRanges[followerRange][0] <= influencer.follower_count && influencer.follower_count <= followerRanges[followerRange][1]) : true;
        const matchedTags = selectedTags.length === 0 || (influencer.tags && influencer.tags.some(tag => selectedTags.includes(tag)));

        return matchesSearchTerm && matchesState && matchesCity && matchedBookmark && 
               matchedSelected && matchedFeatured && matchedFollowerRange && matchedTags;
      });

    return filtered.sort((a, b) => {
      return b.featured === a.featured ? 0 : b.featured ? 1 : -1;
    });
  }

  const groupInfluencersByPage = (influencers: Influencer[], groupSize: number) => {
    let groups = [];
    for (let i = 0; i < influencers.length; i += groupSize) {
      groups.push(influencers.slice(i, i + groupSize));
    }
    if (groups.length === 0) {
      groups = [[]];
    }
    return groups;
  }

  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const uniqueStates = new Set<string>();
  const uniqueCities = new Set<string>();
  const uniqueTags = new Set<string>();
  Object.values(influencers ?? {}).forEach((influencer) => {
    uniqueStates.add(influencer.state);
    if (selectedState === influencer.state) {
      if (influencer.city !== "") {
        const cities = influencer.city.split(", ");
        cities.forEach((city) => {
          uniqueCities.add(city);
        });
      }
    }
    if (influencer.tags) {
      influencer.tags.forEach(tag => uniqueTags.add(tag));
    }
  });

  const followerRanges = {
    "": [0, Infinity],
    "Nano (0 - 10k)": [0, 10000],
    "Micro (10k - 50k)": [10000, 50000],
    "Macro (50k - 500k)": [50000, 500000],
    "Celebrity (500k+)": [500000, Infinity]
  };

  const handleCampaignLaunched = async () => {
    console.log("Starting campaign launch...");
    setIsLaunching(true);
    setIsFinishButtonDisabled(true);
    if(selectedInfluencers.size < 1) {
      setIsLaunching(false);
      setIsFinishButtonDisabled(false);
      history.goBack();
      return;
    }

    try {
      // Get campaign data first
      const campaignData = await getInfluencerCampaign(campaignID);
     
      if (!campaignData) {
        throw new Error(`Campaign with ID ${campaignID} not found`);
      }

      let successfulCollabs = 0;

      // Create collaborations
      for (const influencerID of selectedInfluencers) {
        try {
          const influencer = influencers[influencerID];
          if (!influencer) {
            console.error(`Influencer with ID ${influencerID} not found`);
            continue;
          }
          
          await createCollaboration(
            `${influencerID}_${campaignID}`,
            influencerID,
            campaignID,
            campaignData.cash,
            campaignData.credit,
            campaignData.deliverableCounts,
            {},
            [],
            [],
            "pendingInfluencerApproval", 
          );
          console.log(`Collaboration created for influencer ${influencerID}`);
          successfulCollabs++;

          sendCollabInviteEmail(influencer.email_address, client.accountName, influencer.instagramHandle);            

          if (influencer.phoneNumber) {
            sendCollabInviteSms(influencerID, influencer.phoneNumber, client.accountName);
          }
        } catch (error) {
          console.error(`Error creating collaboration for influencer ${influencerID}:`, error);
        }
      }

      // Send campaign notification email
      try {
        const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/send-campaign-created-notification-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Environment.FLASK_API_KEY
          },
          body: JSON.stringify({
            client_name: client.accountName,
            campaign_name: campaignData['name']
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to send notification email');
        }
        await response.json();
        
      } catch (error) {
        console.error('Error sending notification email:', error);
      }

      // Update invites remaining as the last step
      if (successfulCollabs > 0 && adminData?.userID) {
        await updateAdminUserRemainingInvites(adminData.userID, successfulCollabs);
      }      
     
      history.goBack();

    } catch (error) {
      console.error('Error launching campaign:', error);
      setIsFinishButtonDisabled(false);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleFinishClick = async () => {
    try {
      const campaignData = await getInfluencerCampaign(campaignID);
      
      if(selectedInfluencers.size > 0) {
        if (campaignData && campaignData.cash && campaignData.cash > 0) {
          setHasCashPayment(true);
        }
        setIsLaunchConfirmationOpen(true);
      } else {
        await handleCampaignLaunched();
      }
    } catch (error) {
      console.error("Error in handleFinishClick:", error);
    }
  };

  const handleConfirmLaunch = async () => {
    setIsLaunchConfirmationOpen(false);
    if (hasCashPayment) {
      setIsPaymentModalOpen(true);
    } else {
      await handleCampaignLaunched();
    }
  };

  const handlePaymentSuccess = async () => {
    setIsPaymentModalOpen(false);
    await handleCampaignLaunched();
  };

  const remainingCollabRequests = adminData?.invitesRemaining - selectedInfluencers.size;

  return (
    <Box height="100vh" p={4}>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
        <Box>
          <Text mb="4px" fontWeight="bold" fontSize="20px">
            {campaignID ? "Select Influencers" : "Find Influencers"}
          </Text>
          <Text fontSize="sm" color="gray.500" mb="8px">
            <Text as="span" color={remainingCollabRequests < 10 ? "red.500" : (remainingCollabRequests) < 31 ? "yellow.500" : "green.500"}>
              {remainingCollabRequests}
            </Text> Collab Requests Remaining
          </Text>
        </Box>
        {campaignID && (
          <Flex direction="column" align="flex-end" gap={2}>
            <Button
              bg="black"
              color="white"
              size="lg"
              variant="solid"
              onClick={handleFinishClick}
              isDisabled={isFinishButtonDisabled}
              isLoading={isLaunching}
              loadingText="Launching Campaign"
              _hover={{
                bg: "gray.800"
              }}
              _active={{
                bg: "gray.900"
              }}
            >
              Finish ({selectedInfluencers.size} selected)
            </Button>
            <Text fontSize="sm" color="gray.500">
              {isLaunching ? "Processing your request..." : "You can add influencers later"}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex gap={6}>
        <Box flex="1">
          <Input
            placeholder="Search by name, email, or Instagram handle"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            _placeholder={{ color: 'gray.400' }}
          />

          <Box overflow="auto" maxHeight="calc(100vh - 200px)">
            <InfluencersList 
              key={currPage} 
              influencers={visibleInfluencers[currPage]} 
              bookmarkedInfluencers={client?.bookmarkedInfluencers}
              clientID={client?.id} 
              creatingCampaign={campaignID!=null} 
              handleInfluencerSelected={toggleInfluencerSelected}
              selectedInfluencers={selectedInfluencers}
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
            />
            <Pagination
              currPage={currPage}
              setCurrPage={setCurrPage}
              totalPages={visibleInfluencers.length}
            />
          </Box>
        </Box>

        <VStack 
          width="250px" 
          spacing={4} 
          align="stretch"
          position="sticky"
          top={4}
        >
          <Select
            placeholder="State / Country"
            value={selectedState}
            onChange={(e) => {
              setSelectedState(e.target.value);
              setSelectedCity("");
            }}
            textAlign="left"
            sx={{
              "& option": { textAlign: "left" }
            }}
            border="1px solid"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            fontWeight="normal"
          >
            {[...uniqueStates].sort().map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </Select>

          <Select
            placeholder="City"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            textAlign="left"
            sx={{
              "& option": { textAlign: "left" }
            }}
            isDisabled={!selectedState}
            border="1px solid"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            fontWeight="normal"
          >
            {[...uniqueCities].sort().map((city, index) => (
              <option key={index} value={city}>{city}</option>
            ))}
          </Select>

          <Select
            placeholder="Follower Count"
            value={followerRange}
            onChange={(e) => setFollowerRange(e.target.value)}
            textAlign="left"
            sx={{
              "& option": { textAlign: "left" }
            }}
            border="1px solid"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            fontWeight="normal"
          >
            {Object.keys(followerRanges).map((text) => (
              text !== "" && <option key={text} value={text}>{text}</option>
            ))}
          </Select>

          <Button
            rightIcon={<Icon as={FaStar} color={filterFeatured ? "white" : "#0080FE"} />}
            onClick={() => setFilterFeatured(!filterFeatured)}
            justifyContent="flex-start"
            backgroundColor={filterFeatured ? "green.500" : "white"}
            color={filterFeatured ? "white" : "black"}
            width="100%"
            border="1px solid"
            borderColor={filterFeatured ? "green.500" : "gray.300"}
            _hover={{ 
              borderColor: filterFeatured ? "green.600" : "gray.400",
              bg: filterFeatured ? "green.600" : "gray.50"
            }}
            fontWeight="normal"
            borderRadius="4px"
            height="40px"
          >
            Featured
          </Button>

          <Button 
            onClick={() => setFilterBookmarked(!filterBookmarked)} 
            width="100%"
            justifyContent="flex-start"
            rightIcon={filterBookmarked ? <FaBookmark color="green" /> : <FaRegBookmark />}
            border="1px solid"
            borderColor={filterBookmarked ? "green.500" : "gray.300"}
            bg={filterBookmarked ? "green.500" : "white"}
            color={filterBookmarked ? "white" : "black"}
            _hover={{ 
              borderColor: filterBookmarked ? "green.600" : "gray.400",
              bg: filterBookmarked ? "green.600" : "gray.50"
            }}
            fontWeight="normal"
            borderRadius="4px"
            height="40px"
          >
            Bookmarked
          </Button>

          {campaignID && (
            <Button
              onClick={() => setFilterSelected(!filterSelected)}
              width="100%"
              justifyContent="flex-start"
              rightIcon={
                <Checkbox 
                  isChecked={filterSelected}
                  colorScheme="blue"
                  size="lg"
                  pointerEvents="none"
                  sx={{
                    '& span[class*="checkbox"]': {
                      borderColor: filterSelected ? "blue.500" : "gray.300",
                    }
                  }}
                />
              }
              border="1px solid"
              borderColor={filterSelected ? "gray.300" : "gray.300"}
              bg="white"
              color="black"
              _hover={{ 
                borderColor: "gray.400",
                bg: "gray.50"
              }}
              fontWeight="normal"
              borderRadius="4px"
              height="40px"
            >
              Selected
            </Button>
          )}

          <Box mt="auto" pt={4} borderTop="1px solid" borderColor="gray.300">
            <Box>
              <Text mb={2} fontWeight="medium">Available Tags</Text>
              
              {/* Available Tags Section (Scrollable) */}
              <Box maxHeight="200px" overflowY="auto" mb={4} borderBottom="1px solid" borderColor="gray.200" pb={3}>
                <Flex gap={2} flexWrap="wrap">
                  {[...uniqueTags].sort().map((tag, index) => {
                    if (selectedTags.includes(tag)) return null;
                    
                    const tagColors = [
                      { bg: "blue.50", color: "blue.600" },
                      { bg: "green.50", color: "green.600" },
                      { bg: "purple.50", color: "purple.600" },
                      { bg: "pink.50", color: "pink.600" },
                      { bg: "orange.50", color: "orange.600" },
                      { bg: "teal.50", color: "teal.600" },
                    ];
                    
                    const colorIndex = index % tagColors.length;
                    const { bg, color } = tagColors[colorIndex];

                    return (
                      <Box
                        key={tag}
                        bg={bg}
                        color={color}
                        px={2}
                        py={1}
                        borderRadius="md"
                        fontSize="sm"
                        cursor="pointer"
                        onClick={() => setSelectedTags([...selectedTags, tag])}
                        _hover={{ 
                          bg: bg.replace('50', '100'),
                          transform: "translateY(-1px)"
                        }}
                        transition="all 0.2s"
                      >
                        {tag}
                      </Box>
                    );
                  })}
                </Flex>
              </Box>

              {/* Selected Tags Section */}
              <Box>
                <Text mb={2} fontWeight="medium">Selected Tags</Text>
                <Flex gap={2} flexWrap="wrap">
                  {selectedTags.map(tag => {
                    const tagIndex = [...uniqueTags].sort().indexOf(tag);
                    const tagColors = [
                      { bg: "blue.50", color: "blue.600" },
                      { bg: "green.50", color: "green.600" },
                      { bg: "purple.50", color: "purple.600" },
                      { bg: "pink.50", color: "pink.600" },
                      { bg: "orange.50", color: "orange.600" },
                      { bg: "teal.50", color: "teal.600" },
                    ];
                    
                    const colorIndex = tagIndex % tagColors.length;
                    const { bg, color } = tagColors[colorIndex];

                    return (
                      <Box
                        key={`selected-${tag}`}
                        bg={bg}
                        color={color}
                        px={2}
                        py={1}
                        borderRadius="md"
                        fontSize="sm"
                        cursor="pointer"
                        onClick={() => setSelectedTags(selectedTags.filter(t => t !== tag))}
                        _hover={{ 
                          bg: bg.replace('50', '100'),
                          transform: "translateY(-1px)"
                        }}
                        transition="all 0.2s"
                      >
                        {tag}
                        <Text as="span" ml={1}>×</Text>
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            </Box>
          </Box>
        </VStack>
      </Flex>

      <PaymentModal 
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onPaymentMethodSaved={handlePaymentSuccess}
        clientId={clientID}
      />
      <UpgradeModal 
        isOpen={isUpgradeModalOpen} 
        onClose={() => setIsUpgradeModalOpen(false)} 
      />
      <LaunchConfirmationModal 
        isOpen={isLaunchConfirmationOpen}
        onClose={() => setIsLaunchConfirmationOpen(false)}
        onConfirm={handleConfirmLaunch}
        selectedCount={selectedInfluencers.size}
        hasCashPayment={hasCashPayment}
      />
      {isLaunching && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="blackAlpha.300"
          zIndex={1000}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="xl"
            textAlign="center"
          >
            <Spinner size="xl" mb={4} color="green.500" />
            <Text fontSize="lg" fontWeight="medium">
              Launching Campaign...
            </Text>
            <Text fontSize="sm" color="gray.500" mt={2}>
              Please wait while we process your request
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}
