import React from 'react';
import { Box, Text, Flex, Icon } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import InstagramProfilePic from '../InstagramProfilePic';
import { Influencer } from '../../types';
import { formatCount } from '../../utils/metricsUtils';
interface InfluencerInfoProps {
  influencer: Influencer;
}

const InfluencerInfo: React.FC<InfluencerInfoProps> = ({ influencer }) => {
  return (
    <Flex alignItems="center" mb={8}>
      <InstagramProfilePic profile_pic_url={influencer?.profile_pic_url} instagramHandle={influencer?.instagramHandle} imageSize="120px" />
      <Box ml={8}>
        <Text fontWeight="bold" fontSize="xl">@{influencer?.instagramHandle}</Text>
        {influencer?.city && influencer?.state && (
          <Flex alignItems="center" mt={2}>
            <Icon as={FaMapMarkerAlt} color="gray.500" mr={2} />
            <Text fontSize="md">{influencer?.city}, {influencer?.state}</Text>
          </Flex>
        )}
        <Flex alignItems="center" mt={2}>
          <Icon as={FaInstagram} color="gray.500" mr={2} />
          <Text fontSize="md">{formatCount(influencer?.follower_count)} followers</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default InfluencerInfo; 