import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { CollaborationsData, collabStates, Influencer, InfluencerCampaign } from '../types';

interface StatusButtonProps {
  status: string;
  collaboration: CollaborationsData;
  campaign?: InfluencerCampaign;
  influencer?: Influencer;
  clientName?: string;
  size?: string;
}

const StatusButton: React.FC<StatusButtonProps> = ({
  status,
  collaboration,
  campaign,
  influencer,
  clientName,
  size = 'md'
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleClick = () => {
    if (collabStates(collaboration)?.component) {
      setIsModalOpen(true);
    }
  };

  const ModalComponent = collabStates(collaboration)?.component;

  return (
    <>
      <Button
        bg={collabStates(collaboration)?.color || 'gray.300'}
        color="black"
        size={size}
        px={6}
        onClick={handleClick}
        _hover={{ opacity: 0.8 }}
      >
        {collabStates(collaboration)?.label || 'Unknown Status'}
      </Button>

      {ModalComponent && (
        <ModalComponent
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          collab={collaboration}
          campaign={campaign}
          influencer={influencer}
          clientName={clientName}
        />
      )}
    </>
  );
};

export default StatusButton;