import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  HStack,
  Avatar,
  Flex,
  IconButton,
  Link,
  Badge
} from '@chakra-ui/react';
import moment from 'moment';
import { FaInstagram, FaTiktok, FaEnvelope } from 'react-icons/fa';

import { CollaborationsData, Influencer, InfluencerCampaign, collabStates } from '../../types';
import CounteredCollabOptions from './CounterCollabOptions';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';

interface CounterCalendarProps {
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
  setCounteredCollabsCount: (count: number) => void;
  global: boolean;
  clientName: string;
}

const CounterCalendar: React.FC<CounterCalendarProps> = ({ clientId, isOpen, onClose, setCounteredCollabsCount, global, clientName }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedCollab, setSelectedCollab] = useState<CollaborationsData | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const allCollaborations = useContext(CollabsDataContext);
  const collaborations = Object.values(allCollaborations).filter(collab => collab.status === 'influencerCountered');
  const campaigns = useContext(InfluencerCampaignsContext);
  const influencers = useContext(InfluencersDataContext); 
  
  const sortedCollabs = Object.values(collaborations).sort((a, b) => {
    if (!a.collabCounteredTime && !b.collabCounteredTime) return 0;
    if (!a.collabCounteredTime) return 1;
    if (!b.collabCounteredTime) return -1;
    return moment(a.collabCounteredTime.toDate()).diff(moment(b.collabCounteredTime.toDate()));
  });

  useEffect(() => {
    setCounteredCollabsCount(sortedCollabs.length);
  }, [sortedCollabs.length]);

  const handleBadgeClick = (collab: CollaborationsData) => {
    setSelectedCollab(collab);
    setIsDetailModalOpen(true);
  };

  const renderCollabList = (collabs: CollaborationsData[]) => (

    <VStack align="stretch" spacing={4} overflowY="auto" maxHeight="60vh">
      {collabs.map((collab) => {
        const influencer = influencers[collab.influencerID];
        return (
          <HStack key={collab.id} spacing={4} p={4} borderWidth={1} borderRadius="md">
            <Avatar 
              src={influencer?.profile_pic_url} 
              name={influencer?.instagramHandle}
              size="lg"
            />
            <Box flex={1}>
              <Flex alignItems="center">
                <Text fontWeight="bold">@{influencer?.instagramHandle}</Text>
                <Flex ml={2}>
                  {influencer?.instagramHandle && ( 
                    <IconButton
                      icon={<FaInstagram />}
                      aria-label="Instagram"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://instagram.com/${influencer.instagramHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />)}
                  {influencer?.tiktokHandle && (
                    <IconButton
                      icon={<FaTiktok />}
                      aria-label="TikTok"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://www.tiktok.com/@${influencer.tiktokHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                  {influencer?.email_address && (
                    <IconButton icon={<FaEnvelope size={16} />} aria-label="Email" variant="ghost" colorScheme="gray" as="a" href={`mailto:${influencer?.email_address}`} />
                  )}
                </Flex>
              </Flex>
              <Text fontSize="sm">
                {collab.collabCounteredTime 
                  ? moment(collab.collabCounteredTime.toDate()).format('MMMM D, YYYY, h:mm A')
                  : 'Collab Not Countered'}
              </Text>
              {global && <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.clientName}</Text>}
              <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.name}</Text>
            </Box>
            <Badge
              bg={collabStates(collab)?.color || 'gray.300'}
              onClick={() => handleBadgeClick(collab)}
              cursor="pointer"
              _hover={{ bg: 'gray.600' }}
            >
              {collabStates(collab)?.label || 'Unknown Status'}
            </Badge>
          </HStack>
        );
      })}
    </VStack>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="50vw">
          <ModalHeader>Countered Collabs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs index={currentTab} onChange={setCurrentTab}>
              <TabList>
                <Tab>Countered ({sortedCollabs.length})</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderCollabList(sortedCollabs)}</TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>

      {selectedCollab && (
        <CounteredCollabOptions
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailModalOpen(false)}
          collab={selectedCollab}
          campaign={campaigns[selectedCollab.influencerCampaignID]}
          influencer={influencers[selectedCollab.influencerID]}
          clientName={clientName}
        />
      )}
    </>
  );
};

export default CounterCalendar;