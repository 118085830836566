import React, { useState, useEffect, useContext } from 'react';
import { Box, Flex, Text, Button, Icon, useToast, Center, Spinner, Heading } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { refreshPostMetrics } from 'services/flaskService';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../../firebase'; // Adjust this path to match your firebase config location
import Overview from './components/Overview/index';
import Profiles from './components/Profiles/index';

interface ActiveCampaignProps {
  clientID: string;
  campaignId: string;
}

const ActiveCampaign: React.FC<ActiveCampaignProps> = ({ clientID, campaignId }) => {
  const [activeTab, setActiveTab] = useState<'overview' | 'profiles'>('overview');
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [metricsData, setMetricsData] = useState<any>(null);
  const [refreshError, setRefreshError] = useState<string | null>(null);
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState<{
    name: string;
    startDate: Date;
    endDate: Date;
  } | null>(null);
  const toast = useToast();
  const collabs = useContext(CollabsDataContext);

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const campaignRef = doc(firestore, "influencerCampaigns", campaignId);
        const campaignDoc = await getDoc(campaignRef);
        
        if (campaignDoc.exists()) {
          const data = campaignDoc.data();
          setCampaignDetails({
            name: data.name,
            startDate: data.startDate?.toDate(), // Convert Firestore Timestamp to Date
            endDate: data.endDate?.toDate(),
          });
        } else {
          console.log("No such campaign!");
        }
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      }
    };

    fetchCampaignDetails();
  }, [campaignId]);

  useEffect(() => {
    const refreshMetrics = async () => {
      if (hasInitiallyLoaded || !collabs) return;
      
      console.log('Refreshing metrics...');
      
      setIsRefreshing(true);
      setRefreshError(null);
      try {
        const campaignCollabs = Object.values(collabs).filter(
          collab => collab.influencerCampaignID === campaignId
        );
        
        if (campaignCollabs.length > 0) {
          const collabIds = campaignCollabs.map(collab => collab.id);
          const data = await refreshPostMetrics(collabIds);
          setMetricsData(data);
          
          toast({
            title: 'Metrics refreshed successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error refreshing post metrics:', error);
        setRefreshError('Failed to refresh metrics');
        toast({
          title: 'Failed to refresh metrics',
          description: 'The data shown may be outdated',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsRefreshing(false);
        setHasInitiallyLoaded(true);
      }
    };

    refreshMetrics();
  }, [campaignId, hasInitiallyLoaded]);

  // Format the date to a readable string
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        minHeight="calc(100vh - 135px)"
        position="relative"
        display="flex"
        flexDirection="column"
        p={{ base: 4, md: 6 }}
      >
        {/* Header Section */}
        <Flex direction="column" mb={6}>
          {/* Top row with titles */}
          <Flex justify="space-between" mb={3}>
            <Text fontSize="2xl" fontWeight="bold">
              Campaign Insights
            </Text>
            <Text fontSize="2xl" fontWeight="medium">
              {campaignDetails?.name || 'Campaign'}
            </Text>
          </Flex>
          
          {/* Bottom row with date and buttons */}
          <Flex justify="space-between" align="center">
            <Flex gap={2}>
              <Button
                bg={activeTab === 'overview' ? "gray.800" : "gray.100"}
                color={activeTab === 'overview' ? "white" : "gray.600"}
                _hover={{ bg: activeTab === 'overview' ? 'gray.700' : 'gray.200' }}
                size="sm"
                onClick={() => setActiveTab('overview')}
              >
                Overview
              </Button>
              <Button
                bg={activeTab === 'profiles' ? "gray.800" : "gray.100"}
                color={activeTab === 'profiles' ? "white" : "gray.600"}
                _hover={{ bg: activeTab === 'profiles' ? 'gray.700' : 'gray.200' }}
                size="sm"
                onClick={() => setActiveTab('profiles')}
              >
                Collabs
              </Button>
            </Flex>
            {campaignDetails?.startDate && (
              <Text fontSize="md" color="gray.600">
                {formatDate(campaignDetails.startDate)} - {formatDate(campaignDetails.endDate)}
              </Text>
            )}
          </Flex>
        </Flex>

        {/* Content Section */}
        {activeTab === 'overview' ? (
          <Overview 
            clientID={clientID} 
            campaignId={campaignId} 
            metricsData={metricsData}
            isLoading={isRefreshing}
            error={refreshError}
          />
        ) : (
          <Profiles clientID={clientID} campaignId={campaignId} />
        )}
      </Box>
    </Box>
  );
};

export default ActiveCampaign;