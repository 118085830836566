import Environment from "../environment";

const API_KEY = Environment.FLASK_API_KEY;
const HOST_URL = Environment.FLASK_HOST_URL;
const HOST_URL_FOODFLUENCE = Environment.FLASK_HOST_URL_FOODFLUENCE;

export default async function getUgcForClient(
  client_id: string
): Promise<string[] | null> {
  const endpoint = `${HOST_URL}/firebase-storage/get-ugc-for-client/?client_id=${client_id}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching UGC:", error);
    return null;
  }
}

async function getInstagramProfileData(username: string): Promise<any> {
  const endpoint = `${HOST_URL}/instagram/get-profile/${username}`;

  // Create a timeout promise
  const timeout = new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 10000); // 10 seconds
  });

  try {
    const fetchPromise = fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    });

    // Race the fetch against the timeout
    return Promise.race([fetchPromise, timeout]);
  } catch (error) {
    console.error("Error fetching Instagram profile data:", error);
    return null;
  }
}


async function getOfferDetail(offerId: string): Promise<any> {
  const endpoint = `${HOST_URL}/offers/details/${offerId}`;

  // Create a timeout promise
  const timeout = new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 10000); // 10 seconds
  });

  try {
    const fetchPromise = fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    });

    // Race the fetch against the timeout
    return Promise.race([fetchPromise, timeout]);
  } catch (error) {
    console.error("Error fetching offer detail:", error);
    return null;
  }
}

const loadImageViaProxy = async (originalUrl: string): Promise<Uint8Array> => {
  const endpoint = `${HOST_URL}/load-image/?url=${encodeURIComponent(
    originalUrl
  )}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.arrayBuffer();
      return new Uint8Array(data);
    } else {
      throw new Error("Failed to load image");
    }
  } catch (error) {
    throw new Error("Failed to load image");
  }
};

const createPaymentIntent = async (
  amount: number,
  email: string,
  brandName: string
): Promise<string> => {
  const endpoint = `${HOST_URL}/payment/create-client-payment`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount_in_usd: amount,
        email,
        brand_name: brandName,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.client_secret;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    return "";
  }
};

/**
 * @info Add payment method to the customer
 * @param paymentMethodId 
 * @param email 
 * @returns 
 */
const addPaymentMethodToCustomer = async (
  paymentMethodId: string,
  email: string,
): Promise<string> => {
  const endpoint = `${HOST_URL}/payment/create-client-payment-method`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_method: paymentMethodId,
        email,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding payment method:", error);
    return "";
  }
};



/**
 * @info delete payment method of the user
 * @param paymentMethodId 
 * @param email 
 * @returns 
 */
const deletePaymentMethod = async (
  paymentMethodId: string,
  email: string,
): Promise<string> => {
  const endpoint = `${HOST_URL}/payment/delete-client-payment-method`;

  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_method: paymentMethodId,
        email,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while delete payment method:", error);
    return "";
  }
};


/**
 *  @info Get payment methods of the customer
 *  @param email 
 * @returns  payment methods of the customer
 */
const getPaymentMethods = async (
  email: string,
): Promise<any[]> => {
  const endpoint = `${HOST_URL}/payment/client-payment-methods?user=${encodeURIComponent(email)}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while get payment method:", error);
    return []
  }
};


/**
 * @info Get invoices of customer
 * @param email 
 * @returns All the invoices of customer
 */
const getInvoices = async (
  email: string,
): Promise<any[]> => {
  const endpoint = `${HOST_URL}/payment/client-payment-invoices?user=${encodeURIComponent(email)}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while get invoices:", error);
    return [];
  }
};

const sendPrivateOffer = async (phoneNumber: string, offerID: string) => {
  const endpoint = `${HOST_URL}/firestore/send-offer-to-wallet/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone_number: phoneNumber, offer_id: offerID }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending private offer:", error);
    return "";
  }
};

const sendCustomerOffer = async (offerID: string, clientID: string) => {
  const endpoint = `${HOST_URL}/send-sms/send-customer-texts-for-offer/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({offer_id: offerID, client_id: clientID }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending customer offer:", error);
    return "";
  }
};


const createShopifyPriceRule = async (
  client_id: string,
  offer_name: string,
  amount: number,
  discount_type: string,
  product_id: string,
  collection_id: string,
  min_purchase_amount: number,
  prerequisite_collection_ids: string,
  prerequisite_quantity: number
) => {
  const endpoint = `${HOST_URL}/integrations/shopify/create-price-rule/`;
  if (!product_id) {
    // if product id is empty, set to "none"
    product_id = "none";
  }
  console.log("create shopify price rule----payload", {
    client_id,
    offer_name,
    amount,
    discount_type,
    product_id,
    collection_id,
    min_purchase_amount,
    prerequisite_collection_ids,
    prerequisite_quantity,
  })

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id,
        offer_name,
        amount,
        discount_type,
        product_id,
        collection_id,
        min_purchase_amount,
        prerequisite_collection_ids,
        prerequisite_quantity,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data.price_rule_id;
  } catch (error) {
    console.error("Error creating Shopify price rule:", error);
    return "";
  }
};

const createDashboardAdminAccount = async (
  email: string,
  password: string,
  account_name: string
) => {
  const endpoint = `${HOST_URL}/admin-dashboard/create-client-dashboard-admin/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, account_name }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating Admin Dashboard account:", error);
    return "";
  }
};

const addAdditionalSeat = async (
  email: string,
  password: string,
  full_name: string,
  admin_id: string
) => {
  const endpoint = `${HOST_URL}/dashboard/add-additional-seat/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email.toLowerCase(), password, full_name, admin_id }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} `);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating Admin Dashboard account:", error);
    return "";
  }
};

/**
 * @info delete additional sheets
 * @param emailId 
 * @returns 
 */
const deleteAdditionalSheet = async (
  emailId: string,
): Promise<string> => {
  const endpoint = `${HOST_URL}/dashboard/delete-additional-seat?user=${encodeURIComponent(emailId)}`;

  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while delete additional seat:", error);
    return "";
  }
};

// Add this new function near the other exported functions
const refreshPostMetrics = async (collabIds: string[]): Promise<any> => {
  const endpoint = `${HOST_URL_FOODFLUENCE}/firestore/refresh-post-metrics/`;

  // Create the request body
  const requestBody = { collabIds: collabIds };

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error refreshing post metrics:", error);
  }
};

const notifyInfluencerShipment = async (collabId: string): Promise<any> => {
  const endpoint = `${HOST_URL_FOODFLUENCE}/send-client-confirmed-shipment/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ collab_id: collabId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error notifying influencer of shipment:", error);
  }
};

const notifyInfluencerTracking = async (collabId: string, trackingNumbers: string[]): Promise<any> => {
  const endpoint = `${HOST_URL_FOODFLUENCE}/send-client-entered-tracking-number/`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        collab_id: collabId,
        tracking_numbers: trackingNumbers 
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error notifying influencer of tracking number:", error);
  }
};

const sendCounterText = async (phoneNumber: string, name: string, client_name: string): Promise<any> => {
  try {
    const response = await fetch(`${HOST_URL_FOODFLUENCE}/business-counter-response/`, {  
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        phone_number: phoneNumber,
        name: name,
        client_name: client_name
      }),
    }); 

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result= await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error sending counter texts:", error.message);
    } else {
      console.error("Error sending counter texts:", error);
    }
    return false;
  }
};

const sendCollabInviteSms = async (influencerID: string, phoneNumber: string, name: string) => {
  try {
    const endpoint = `${HOST_URL_FOODFLUENCE}/send-influencer-collab-sms/`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ influencer_id: influencerID, phoneNumber: phoneNumber, client_name: name }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending collab sms:", error);
    throw error;
  }
}

const sendCollabInviteEmail = async (email: string, client_name: string, ig_username: string) => {
  try {
    const endpoint = `${HOST_URL_FOODFLUENCE}/send-influencer-collab-email/`;
    const response = await fetch(endpoint, {
      method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Environment.FLASK_API_KEY
    },
    body: JSON.stringify({
      email: email,
      client_name: client_name,
      ig_username: ig_username
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
    console.log('API response:', data);
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}
// Add refreshPostMetrics to the export list
export {
  getInstagramProfileData,
  getUgcForClient,
  loadImageViaProxy,
  createPaymentIntent,
  sendPrivateOffer,
  createShopifyPriceRule,
  createDashboardAdminAccount,
  addAdditionalSeat,
  addPaymentMethodToCustomer,
  getPaymentMethods,
  getInvoices,
  deletePaymentMethod,
  deleteAdditionalSheet,
  getOfferDetail, 
  sendCustomerOffer,
  refreshPostMetrics,
  notifyInfluencerShipment,
  notifyInfluencerTracking,
  sendCollabInviteSms,
  sendCollabInviteEmail,
  sendCounterText
};
