import saveAs from "file-saver";

export const isVideo = (url: string) => {
  const lowercaseUrl = url.toLowerCase();
  return lowercaseUrl.includes('.mp4') || 
         lowercaseUrl.includes('.mov') || 
         lowercaseUrl.includes('.avi')
        }

export const handleDownload = (link: string, collabId: string) => {
  const fileExtension = link.split('.').pop()?.slice(0, 3);
  const fileName = `UGC_${collabId}.${fileExtension}`;
  saveAs(link, fileName);
};
